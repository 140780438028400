import Vue from 'vue';
import { useUserStore } from '~/@api/store/userApi';
import { getDYPageContext, isDYEnabledForPage } from '~/tracking/trackingutils';
import { importLogger, importRunTask } from '~/app-utils/dynamic-imports';
import { useDynamicyieldStore } from '~/@api/store/dynamicyieldApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';

/**
 * Base component for DynamicYield components
 */
export default Vue.extend({
	name: 'DyComponentBase',
	data() {
		return {
			dyLanguage: 'de',
			dySelectorName: undefined,
			dyChoice: undefined,
			dyBaseFetchInProgress: false,
			previousData: undefined,
			dyLoggerDebug: false,
		};
	},
	computed: {
		isDYEnabled() {
			return isDYEnabledForPage(this.$store);
		},
		dyStoredLiveData() {
			return useDynamicyieldStore(this.$store).state.data[this.dataKey];
		},
		dyStoredData() {
			return this.getStoredData();
		},
		dyStoredDataEntryExists() {
			return !!this.dyStoredData;
		},
		dataKey() {
			return useDynamicyieldStore(this.$store).api.buildDyDataKey(this.uid);
		},
	},
	methods: {
		/**
		 * Method which should be called to fetch the data from dynamic yield
		 * @param {string} selectorName campaign selector, defined in dynamic yield
		 * @param {function} callback callback function
		 * @param {boolean} [waitForChoice=false] should wait for choice
		 */
		async fetchDy(selectorName: string, callback: () => void, waitForChoice = false) {
			this.dySelectorName = selectorName;

			if (
				useServerContextStore(this.$store).state.session.dynamicYieldApiToken &&
				this.isDYEnabled &&
				!this.dyBaseFetchInProgress
			) {
				try {
					this.dyBaseFetchInProgress = true;

					const { yieldToMain } = await importRunTask();

					await yieldToMain();

					const dyChoice = await useDynamicyieldStore(this.$store).api.fetch({
						selector: this.dySelectorName,
						pageContext: getDYPageContext(this.$route.matched, this.$store),
						forceUpdate: false,
						waitForChoice,
					});

					await yieldToMain();

					this.dyLanguage =
						useUserStore(this.$store).state.user?.language?.isocode || this.dyLanguage;

					if (dyChoice) {
						this.dyChoice = dyChoice;
					}

					this.loggerDebug(`${this.dySelectorName} received dyChoice `, this.dyChoice);

					if (callback) {
						callback();
					}
				} catch (e) {
					if (!this.isDYEnabled) {
						importLogger().then(({ default: logger }) => {
							logger?.debug(
								`Unable to fetch DY data for ${this.dySelectorName} as DY is not enabled. Use ?_eT to enable it.`,
								e,
							);
						});
					} else {
						importLogger().then(({ default: logger }) => {
							logger?.debug(`Could not load DY data for selector "${this.dySelectorName}"`, e);
						});
					}
				} finally {
					this.dyBaseFetchInProgress = false;
				}
			}
		},
		storeData(data) {
			this.loggerDebug(`Store data for ${this.dataKey}`);
			importRunTask().then(({ runTask }) => {
				runTask(() => useDynamicyieldStore(this.$store).api.storeData(this.dataKey, data));
			});
		},
		clearStoreData() {
			this.loggerDebug(`Clear stored data for ${this.dataKey}`);
			importRunTask().then(({ runTask }) => {
				runTask(() => useDynamicyieldStore(this.$store).api.clearData(this.dataKey));
			});
		},
		removePrefetchedChoice() {
			if (this.dySelectorName) {
				this.loggerDebug(`Clear choice for ${this.dySelectorName}`);
				importRunTask().then(({ runTask }) => {
					runTask(() => useDynamicyieldStore(this.$store).api.clearData(this.dataKey));
				});
			}
		},
		clearDyDataWhenInBrowser() {
			if (process.browser) {
				// Clear stored data and possible retrieved prefetch when (eg when component destroyed)
				// in browser to ensure fresh DY data, when campaign component is next time displayed.
				// Only do this in browser, to ensure data will be kept from server to client
				// to prevent a re-fetch after ssr
				this.loggerDebug(`About to destroy stored and prefetched data for ${this.dataKey}`);
				importRunTask().then(({ runTask }) => {
					runTask(() => {
						this.clearStoreData();
						this.removePrefetchedChoice();
					});
				});
			}
		},
		getStoredData() {
			const data = this.dyStoredLiveData;

			if (data) {
				this.previousData = data;
			}

			return this.previousData;
		},

		loggerDebug(msg: string, obj: any | undefined = undefined) {
			if (!useDynamicyieldStore(this.$store).state.config.debugging.active) return;

			importLogger().then(({ default: logger }) => {
				logger?.debug(`[DY] ${msg}`, obj);
			});
		},
	},
});
