import Vue from 'vue';
import { useCmsContentStore } from '~/@api/store/cmsContentApi';
import { useRoutingStore } from '~/@api/store/routingApi';
import { useSearchStore } from '~/@api/store/searchApi';

export default Vue.extend({
	name: 'DefaultTemplate',
	props: {
		footer: { type: Boolean, default: true },
		header: { type: Boolean, default: true },
	},
	data() {
		return {
			isContentPage: false,
			isMyAccountPage: false,
			isLoginPage: false,
			isProductPage: false,
			is404Page: false,
			isSearchNoResults: false,
			isStoreDetailPage: false,
			isPasswordResetPage: false,
			isProductListingPage: false,
		};
	},
	computed: {
		headerComponentId() {
			return Object.keys(useCmsContentStore(this.$store).state.references || []).find((key) =>
				key.startsWith('OSPHeaderComponent__'),
			);
		},
	},
	watch: {
		'$route.fullPath': {
			immediate: true,
			handler() {
				this.updatePageTypes();
			},
		},
	},
	mounted() {
		// Initialize the Simple State Manager after the app has been mounted
		this.$nuxt.context.app.ssm.initialize();
	},
	methods: {
		updatePageTypes() {
			// Page types by route path / meta / params
			this.isMyAccountPage = this.$route.path.includes('my-account');
			this.isLoginPage = this.$route.path.includes('login/index.html');
			this.isProductPage = this.$route.meta.spaType === 'product';
			this.isContentPage = this.$route.meta.spaType === 'content';
			this.isStoreDetailPage = !!this.$route.params.storeCode;
			this.isProductListingPage = this.$route.meta.spaType === 'category';
			this.isPasswordResetPage = this.$route.meta.spaType === 'password_reset';

			// Page types by routing spaData page
			this.is404Page =
				useRoutingStore(this.$store).state.spaData.datalayer?.page?.pageInfo?.pageID === 'notFound';
			this.isSearchNoResults =
				useRoutingStore(this.$store).state.spaData.datalayer?.page?.pageInfo?.pageID === 'search' &&
				useSearchStore(this.$store).state.response?.pagination?.totalCount === 0;
		},
	},
});
