import { render, staticRenderFns } from "./dy-promo-bar.vue?vue&type=template&id=07abc484&"
import script from "./dy-promo-bar.ts?vue&type=script&lang=ts&"
export * from "./dy-promo-bar.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThreadTaskWrapper: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/ThreadTaskWrapper/ThreadTaskWrapper.vue').default})
