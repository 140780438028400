import { Store } from 'vuex';
import { RootState } from '~/@api/store.types';
import {
	MYACCOUNT_A_FETCH_NAVIGATION,
	MYACCOUNT_A_FETCH_ORDERS,
	MYACCOUNT_A_UPDATE_PERSONAL_DETAILS,
	MYACCOUNT_M_SAVE_ORDER,
} from '~/@constants/store';
import { Order, PersonalDetailsRequest } from '~/generated/hybris-raml-api';

export default function (store: Store<RootState>) {
	return {
		fetchNavigation: () => {
			return store.dispatch(MYACCOUNT_A_FETCH_NAVIGATION, null, { root: true });
		},

		fetchOrders: (amount?: number) => {
			return store.dispatch(MYACCOUNT_A_FETCH_ORDERS, amount, { root: true });
		},

		saveOrder: (order: Order) => {
			return store.commit(MYACCOUNT_M_SAVE_ORDER, order, { root: true });
		},

		updatePersonalDetails: (data: PersonalDetailsRequest) => {
			return store.dispatch(MYACCOUNT_A_UPDATE_PERSONAL_DETAILS, data, { root: true });
		},
	};
}
