import Vue from 'vue';
import sortBy from '@/node_modules/@osp/design-system/assets/js/utilities/sortBy';
import { Card, FlyoutType } from '@/node_modules/@osp/design-system/types/flyout';
import { HeaderFlyout } from '@/node_modules/@osp/design-system/types/header';
import { LinkWithChildren } from '@/node_modules/@osp/design-system/types/navigation';
import { EnhancedBrand } from '@/node_modules/@osp/design-system/types/product';
import { SectionTitleType } from '@/node_modules/@osp/design-system/types/tag';
import { TriggerTag, TriggerTheme } from '@/node_modules/@osp/design-system/types/trigger';
import { Link } from '@/node_modules/@osp/design-system/types/link';
import { BurgerListModel } from '@/node_modules/@osp/design-system/components/BurgerList/BurgerList.props';
import { CallCenterCtaModel } from '@/node_modules/@osp/design-system/components/CallCenterCta/CallCenterCta.props';
import { ClubCtaModel } from '@/node_modules/@osp/design-system/components/ClubCta/ClubCta.props';
import { HeaderDesktopModel } from '@/node_modules/@osp/design-system/components/HeaderDesktop/HeaderDesktop.props';
import { HeaderMobileModel } from '@/node_modules/@osp/design-system/components/HeaderMobile/HeaderMobile.props';
import { MediaModel } from '@/node_modules/@osp/design-system/components/Media/Media.props';
import { MiniCartModel } from '@/node_modules/@osp/design-system/components/MiniCart/MiniCart.props';
import { NavigationListModel } from '@/node_modules/@osp/design-system/components/NavigationList/NavigationList.props';
import { LanguageSwitcherModel } from '@/node_modules/@osp/design-system/components/LanguageSwitcher/LanguageSwitcher.props';
import { LinkListModel } from '@/node_modules/@osp/design-system/components/LinkList/LinkList.props';
import { LoginCtaModel } from '@/node_modules/@osp/design-system/components/LoginCta/LoginCta.props';
import { LogoModel } from '@/node_modules/@osp/design-system/components/Logo/Logo.props';
import { SearchModel } from '@/node_modules/@osp/design-system/components/Search/Search.props';
import { TriggerModel } from '@/node_modules/@osp/design-system/components/Trigger/Trigger.props';
import {
	DEFAULT_FILTERS,
	FILTER_SPECIAL,
} from '@/node_modules/@osp/design-system/components/FlyoutBrands/FlyoutBrands';
import { LcpOptimizationMixin } from '@/node_modules/@osp/design-system/components/mixins/lcp-optimization-mixin';
import { IconText } from '@/node_modules/@osp/design-system/types/text';
import { HeaderProps } from './header.props';
import {
	Image,
	MegaDropdown,
	MegaDropdownCollection,
	RegistrationRequest,
} from '~/generated/hybris-raml-api';
import { importRunTask } from '~/app-utils/dynamic-imports';
import { calculateImageUrl, createImageSources, ImageOptions } from '~/assets/js/images';
import { backend, url } from '~/@api/backend';
import { useCartStore } from '~/@api/store/cartApi';
import { useCmsContentStore } from '~/@api/store/cmsContentApi';
import { useProductsStore } from '~/@api/store/productsApi';
import { useMyAccountStore } from '~/@api/store/myAccountApi';
import { useRoutingStore } from '~/@api/store/routingApi';
import { useSearchStore } from '~/@api/store/searchApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { useUserStore } from '~/@api/store/userApi';
import { useUxStore } from '~/@api/store/uxApi';
import { useVoucherStore } from '~/@api/store/voucherApi';
import { LoginData, Messagebox, MessageboxType } from '~/@api/store.types';
import { suggestedSearchClicked } from '~/tracking/events/suggestedSearchClicked';
import { brandSearch } from '~/tracking/events/brandSearch';
import { trackClubCtaClick } from '~/tracking/events/clubCtaClick';
import { suggestedSearchUsageMarker } from '~/tracking/helpers/suggestedSearchUsageMarker';

// Constant(s) -------------------------------------------------------------------------------------

const CLUB_CTA_COOKIE_NAME = 'ospClubCtaClosed';
const FLYOUT_GRID_AMOUNT = 5;
const FLYOUT_TOP_BRANDS_AMOUNT = 8;
const FLYOUT_HIGHLIGHTED_BRANDS_AMOUNT = 5;

export default Vue.extend({
	name: 'Header', // eslint-disable-line vue/no-reserved-component-names
	mixins: [LcpOptimizationMixin],
	props: HeaderProps,
	data() {
		return {
			headerHeight: 0,
			uspBarAnimating: false,
			isModalVisible: false,
			messageboxData: null as Messagebox,
			userAccount: '',
		};
	},
	computed: {
		pageData() {
			return useCmsContentStore(this.$store).state.references;
		},
		headerData() {
			return (this.pageData || {})[this.uid];
		},
		gender() {
			return useUserStore(this.$store).state.user?.gender;
		},
		genderNavigation(): LinkListModel {
			if (!this.gender) return;

			const rawData = useUserStore(this.$store).state.user?.genders;
			const activeGenderCode = this.gender.code;
			const links = rawData.map((rawItem) => ({
				text: rawItem.name,
				href: rawItem.url,
				uid: rawItem.code.toLowerCase(),
			}));
			const activeLinkIndex = rawData.findIndex(({ code }) => code === activeGenderCode);

			return { uid: 'HEADER_GENDER', links, activeLinkIndex };
		},
		logo(): LogoModel {
			return {
				uid: 'HEADER_LOGO',
				title: this.$t(this.$i18nKeys.logo.title),
				link: this.isBot
					? url(this.$store)
					: this.genderNavigation.links.find((link) => link.text === this.gender.name).href,
				name: 'icons/' + (this.BREAKPOINT.IS_DESKTOP ? `logo__osp` : `logo__osp--multiline`),
				logoWrapperClass:
					(!this.BREAKPOINT.IS_DESKTOP && 'tw-w-28 tw-max-w-full') || 'tw-w-72 tw-justify-center',
			};
		},
		isBot() {
			return useServerContextStore(this.$store).state.userAgent.isBot;
		},
		uspBarItems(): IconText[] {
			const slot = this.headerData?.slots?.find((slot) => slot.position === 'SubHeader');
			const { key } = slot?.components?.[0];
			const rawData = this.pageData?.[key]?.banners;

			return (
				rawData?.map((rawItem) => ({
					icon: rawItem.iconId.replace(/_/g, '-'),
					content: { text: rawItem.text },
				})) ?? []
			);
		},
		utilityCallCenterCta(): CallCenterCtaModel {
			return {
				text: this.$t(this.$i18nKeys.callCenterCta.text),
				url: url(this.$store, '/ccModeLogout'),
				isAgent: useUserStore(this.$store).state.user.ccMode,
			};
		},
		utilityStoresUrl() {
			return url(this.$store, '/store-finder');
		},
		isLoggedIn() {
			return useUserStore(this.$store).state.user.loggedIn;
		},
		utilityLoginCta(): LoginCtaModel {
			return {
				loggedIn: this.isLoggedIn,
				initials: this.initials,
				active: this.$route.path.includes('/de/shop/my-account'),
			};
		},
		utilityClubCta(): ClubCtaModel {
			const { state: userState } = useUserStore(this.$store);
			const { state: voucherState } = useVoucherStore(this.$store);
			const isClubMemberWithBonus = !!(
				userState.user.clubId && voucherState.userGiftBonusCardTotals.value
			);
			let text = this.$t(this.$i18nKeys.footer.logoTileOffers.club.text);
			let href = this.$t(this.$i18nKeys.footer.logoTileOffers.club.url);

			if (isClubMemberWithBonus) {
				text = voucherState.userGiftBonusCardTotals.formattedValue;
				href = url(this.$store, '/my-account/start.html');
			}

			return {
				uid: 'HEADER_UTILITY_CLUB_CTA',
				text,
				href,
				cookieName: `${CLUB_CTA_COOKIE_NAME}_${userState.user?.customerNumber}`,
				hasBonus: isClubMemberWithBonus,
				tracking: {
					click: () => trackClubCtaClick(href),
				},
			};
		},
		utilityLanguageSwitcher(): LanguageSwitcherModel {
			const { languages: rawLanguages, language } = useUserStore(this.$store).state.user;

			return {
				uid: 'HEADER_UTILITY_LANGUAGE_SWITCHER',
				languages: rawLanguages.map((language) => ({
					text: language.shortName,
					href: url(this.$store, language.url),
				})),
				value: { text: language.shortName, href: url(this.$store, language.url) },
			};
		},
		flyoutNavigationData() {
			return this.rawData.filter(
				(data) => !data.category?.hide && !data.category?.hideInNavigation,
			);
		},
		categories(): Link[] {
			if (!this.BREAKPOINT.IS_DESKTOP) {
				return [];
			}

			return this.flyoutNavigationData.map((data) => {
				const isManualLink = !data.isBrandMenu && data.manualLink;
				const isBrandsLink = data.isBrandMenu;
				const text = isManualLink ? data.manualLink.title : data.category.link.title;
				let href = isManualLink ? data.manualLink.url : data.category.link.url;

				href = isBrandsLink ? data.manualLink.url : href;

				return { text, href };
			});
		},
		flyouts(): HeaderFlyout[] {
			if (
				process.server ||
				!this.BREAKPOINT.IS_DESKTOP ||
				!useUxStore(this.$store).state.nuxtReady
			) {
				return [];
			}

			const flyoutsData: HeaderFlyout[] | undefined = this.flyoutNavigationData.map((category) => {
				switch (category.displayType) {
					case 'LIST':
						return this.setFlyoutListData(category);
					case 'MASONRY':
						return this.setFlyoutMasonryData(category);
					case 'LISTTITLE':
						return this.setFlyoutListTitleData(category);
					case 'MASONRYGRID':
						return this.setFlyoutMasonryData(category, true);
					case 'BRANDS':
						return this.setFlyoutBrands(category);
					case 'IMAGE':
						return this.setFlyoutImage(category);
					default:
						return undefined;
				}
			});

			return flyoutsData.filter((flyoutData) => flyoutData !== undefined);
		},
		cart(): MiniCartModel {
			return {
				uid: 'HEADER_CART',
				href: url(this.$store, '/cart.html'),
				amount: useCartStore(this.$store).api.quantity(),
			};
		},
		search(): SearchModel {
			return {
				uid: 'HEADER_SEARCH',
				trends: [],
				getEndpoint: (query: string) => backend.API.V2.AUTOCOMPLETE(this.$store, query),
				searchPageUrl: url(this.$store, 'search'),
				imageLoader: this.imageLoader,
				searchRedirect: this.searchRedirect,
				tracking: this.tracking,
				isFixed: !this.BREAKPOINT.IS_DESKTOP,
			};
		},
		rawData(): MegaDropdownCollection['entries'] {
			const slot = this.headerData.slots.find((slot) => slot.position === 'NavigationBar');
			const { key } = slot.components[0];

			return this.pageData[key]?.entries || [];
		},
		burgerList(): BurgerListModel {
			if (
				process.server ||
				this.BREAKPOINT.IS_DESKTOP ||
				!useUxStore(this.$store).state.nuxtReady
			) {
				return null;
			}

			const { state: searchState } = useSearchStore(this.$store);
			const { state: routingState } = useRoutingStore(this.$store);
			const productNr = routingState.spaData?.datalayer?.product?.productInfo?.productNr;
			const productBreadcrumbs = useProductsStore(this.$store).state.products?.productDetailPage?.[
				productNr
			]?.breadcrumbs;
			const categoryID = routingState.spaData?.datalayer?.page?.category?.categoryID;
			const categoryBreadcrumbs =
				(searchState.response?.categoryCode === categoryID && searchState.response?.breadcrumbs) ||
				undefined;
			const genderSlugs = (useUserStore(this.$store).state.user?.genders || []).map((el) =>
				el.name.toLowerCase(),
			);
			const currentItemPath = (
				(!!productNr && productBreadcrumbs) ||
				(!!categoryID && categoryBreadcrumbs) ||
				[]
			)
				.map((el) => el.url?.match(/\d{8}/)?.[0] || el.name.toLowerCase())
				.filter((el) => !genderSlugs.includes(el));
			const items = this.rawData ? this.getBurgerListItems(currentItemPath) : [];

			return {
				uid: 'HEADER_BURGERLIST',
				items,
				tracking: this.tracking,
				currentItemPath: currentItemPath?.length ? currentItemPath : undefined,
			};
		},
		tracking() {
			return {
				click: ({ term, type, href, clickedText }) => {
					suggestedSearchClicked(term, this.gender.name, type, href, clickedText);
				},
				view: () => {
					suggestedSearchUsageMarker.add();
				},
				brandSearch,
			};
		},
		headerDesktopData(): HeaderDesktopModel {
			return {
				uid: 'HEADER_DESKTOP',
				genderNavigation: this.genderNavigation,
				logo: this.logo,
				searchTrigger: { uid: 'HEADER_SEARCHTRIGGER' },
				utility: {
					uid: 'HEADER_UTILITY',
					callCenterCta: this.utilityCallCenterCta,
					storesUrl: this.utilityStoresUrl,
					loginCta: this.utilityLoginCta,
					clubCta: this.utilityClubCta,
					languageSwitcher: this.utilityLanguageSwitcher,
				},
				categories: this.categories,
				flyouts: this.flyouts,
				cart: this.cart,
				headerHeight: this.headerHeight,
				search: this.search,
			};
		},
		showMobileSearchTrigger(): boolean {
			// keep this logic extracted otherwise a layout shift of <main> section is caused
			return (
				!useUxStore(this.$store).state.pageSwitchInProgress &&
				(!this.$nuxt.context.from ||
					(this.$nuxt.context.from?.fullPath || '').includes('force-device'))
			);
		},
		headerMobileData(): HeaderMobileModel {
			return {
				uid: 'HEADER_MOBILE',
				logo: this.logo,
				searchTrigger: { uid: 'SEARCH_TRIGGER_MOBILE' },
				showSearchTrigger: this.showMobileSearchTrigger,
				loginCta: this.utilityLoginCta,
				cart: this.cart,
				storesCta: { uid: 'STORES_CTA', url: this.utilityStoresUrl },
				clubCta: this.utilityClubCta,
				languageSwitcher: this.utilityLanguageSwitcher,
				genderNavigation: this.genderNavigation,
				burgerList: this.burgerList,
				search: this.search,
			};
		},
		simpleLinkListData(): any {
			const links: Link[] = this.rawData.reduce((items, { category, manualLink }) => {
				if (category) {
					items.push({ text: category.link.title, href: category.link.url });
				} else if (manualLink) {
					items.push({ text: manualLink.title, href: manualLink.url });
				}

				return items;
			}, []);

			return { uid: 'HEADER_NAVIGATIONLIST_SIMPLE', links };
		},
		initials() {
			const firstName = useUserStore(this.$store).state.user?.firstName || '';
			const lastName = useUserStore(this.$store).state.user?.lastName || '';

			return firstName && lastName ? firstName?.[0] + lastName?.[0] : '';
		},
		userName() {
			let name = useUserStore(this.$store).state.user?.firstName;

			if (!name) {
				name = this.$t(this.$i18nKeys.header.modal.guest);
			}

			return this.$t(this.$i18nKeys.header.modal.hello, { name });
		},
		userEmail() {
			return useUserStore(this.$store).state.user?.email;
		},
	},
	watch: {
		showMobileSearchTrigger: {
			immediate: true,
			handler(showTrigger) {
				if (showTrigger) {
					useUxStore(this.$store).api.setPageSwitchHookActive(true);
				}
			},
		},
		isLoggedIn: {
			immediate: true,
			handler(value) {
				this.setUserAccount();

				if (value) {
					useMyAccountStore(this.$store).api.fetchNavigation();
				}
			},
		},
	},
	mounted() {
		this.headerHeight = this.$el.clientHeight;

		this.deferOnFirstUserInteraction(() => (this.uspBarAnimating = true));
		this.$root.$on('header-modal-toggle', this.setModalVisibility);
	},
	beforeDestroy() {
		this.$root.$off('header-modal-toggle', this.setModalVisibility);
	},
	methods: {
		getBurgerListItems(currentItemPath: string[]) {
			return this.rawData
				.filter((item) => !item.hide && !item.hideInNavigation)
				.map(({ category, displayType, id, manualLink }) => {
					let href = category?.link.url;
					let items = [];
					let topBrandsSlider;

					if (manualLink && !category) {
						return { uid: id, text: manualLink.title, href: manualLink.url };
					}

					if (category?.subCategories?.length) {
						if (displayType === 'BRANDS') {
							items = this.setBrandsItems(manualLink.url, category.subCategories);
						} else {
							items = this.setItems(category.subCategories, currentItemPath);
						}
					}

					if (displayType === 'BRANDS') {
						href = manualLink.url;
						topBrandsSlider = category?.trendyCategories.slice(0, 8).reduce(
							(acc, curr, index) => {
								acc.items.push({
									uid: `UID_SLIDE_${index + 1}`,
									name: 'BurgerListSliderItem',
									data: {
										image: {
											media: this.imageLoader(
												{ assetId: curr.link.assetId },
												'',
												'v3-suggested-brand-logo',
												{},
												{ width: 64, height: 64 },
											),
										},
										link: { text: curr.link.text, href: curr.link.url },
									},
								});

								return acc;
							},
							{ uid: 'TOP_BRANDS_SLIDER', options: { slidesPerView: 3.7 }, items: [] },
						);
					}

					return {
						uid: category.id,
						text: category.link.title,
						href,
						items,
						topBrandsSlider,
						searchItems:
							displayType === 'BRANDS'
								? this.setItems(category.subCategories, currentItemPath)
								: [],
						searchPlaceholder: this.$t(this.$i18nKeys.header.mobile.brands.search.placeholder),
					};
				});
		},
		handleLanguageChange(language: Link) {
			location.href = language.href;
		},
		setFlyoutListData(data: MegaDropdown): HeaderFlyout {
			if (data.manualLink) return;

			return {
				flyoutType: FlyoutType.LIST,
				flyoutData: {
					uid: data.id,
					parentCategory: { text: data.category.link.title, href: data.category.link.url },
					list: {
						uid: data.category.id,
						more: {
							href: data.category.link.url,
							text: this.$t(this.$i18nKeys.list.moreCategories),
						},
						links: data.category.subCategories
							.filter((subCategory) => !subCategory.hide && !subCategory.hideInNavigation)
							.map((subCategory) => ({ text: subCategory.link.title, href: subCategory.link.url }))
							.sort((curr, next) => curr.text.localeCompare(next.text)),
					},
				},
			};
		},
		setFlyoutMasonryData(data: MegaDropdown, grid = false): HeaderFlyout {
			const rawCategories = data.category.subCategories
				.filter((rawCategory) => !rawCategory.hide && !rawCategory.hideInNavigation)
				.map((rawCategory) => ({
					uid: rawCategory.id,
					heading: { text: rawCategory.link.title, href: rawCategory.link.url },
					links: rawCategory.subCategories
						.filter((rawSubCategory) => !rawSubCategory.hide && !rawSubCategory.hideInNavigation)
						.map((rawSubcategory) => ({
							text: rawSubcategory.link.title,
							href: rawSubcategory.link.url,
						})),
					more: { href: rawCategory.link.url, text: this.$t(this.$i18nKeys.list.moreCategories) },
					...(grid ? { maxAmount: FLYOUT_GRID_AMOUNT } : {}),
				}));
			const categories = rawCategories.filter((rawCategory) => rawCategory.links.length);
			const moreCategories = rawCategories.filter((rawCategory) => !rawCategory.links.length);

			return {
				flyoutType: FlyoutType.MASONRY,
				flyoutData: {
					uid: data.id,
					parentCategory: { text: data.category.link.title, href: data.category.link.url },
					categories,
					moreCategoriesLabel: {
						title: this.$t(this.$i18nKeys.flyoutMasonry.moreCategoriesLabel),
						type: SectionTitleType.SECONDARY,
					},
					moreCategories,
					specialsLabel: {
						title: this.$t(this.$i18nKeys.specials.label),
						type: SectionTitleType.SECONDARY,
					},
					specials: {
						uid: 'HEADER_SPECIALS',
						links: data.category.specials.map((special) => ({
							href: special.url,
							icon: special.icon,
							text: special.title,
						})),
					},
					grid,
				},
			};
		},
		setFlyoutListTitleData(data: MegaDropdown): HeaderFlyout {
			const rawCategories = data.category.subCategories
				.filter((rawCategory) => !rawCategory.hide && !rawCategory.hideInNavigation)
				.map((rawCategory) => ({
					uid: rawCategory.id,
					heading: { text: rawCategory.link.title, href: rawCategory.link.url },
					links: rawCategory.subCategories
						.filter((rawSubCategory) => !rawSubCategory.hide && !rawSubCategory.hideInNavigation)
						.map((rawSubcategory) => ({
							text: rawSubcategory.link.title,
							href: rawSubcategory.link.url,
						})),
					more: { href: 'url', text: this.$t(this.$i18nKeys.list.moreCategories) },
				}));
			const categories = rawCategories.filter((category) => category.links.length);
			const moreCategories = rawCategories.filter((category) => !category.links.length);

			return {
				flyoutType: FlyoutType.LISTTITLE,
				flyoutData: {
					uid: 'HEADER_FLYOUT_LISTITLE',
					parentCategory: { text: data.category.link.title, href: data.category.link.url },
					categories,
					moreCategoriesLabel: {
						title: this.$t(this.$i18nKeys.flyoutListTitle.moreCategoriesLabel),
						type: SectionTitleType.SECONDARY,
					},
					moreCategories,
					specialsLabel: {
						title: this.$t(this.$i18nKeys.specials.label),
						type: SectionTitleType.SECONDARY,
					},
					specials: {
						uid: 'HEADER_SPECIALS',
						links: data.category.specials.map((special) => ({
							href: special.url,
							icon: special.icon,
							text: special.title,
						})),
					},
				},
			};
		},
		setFlyoutBrands(data: MegaDropdown): HeaderFlyout {
			return {
				flyoutType: FlyoutType.BRANDS,
				flyoutData: {
					uid: data.id,
					parentCategory: { text: data.category.link.title, href: data.category.link.url },
					brandsUrl: data.manualLink.url,
					topBrands: data.category.subCategories
						.filter((subCategory) => !subCategory.hide && !subCategory.hideInNavigation)
						.slice(0, FLYOUT_TOP_BRANDS_AMOUNT)
						.map((subCategory) => ({
							uid: subCategory.id,
							name: subCategory.link.title,
							url: subCategory.link.url,
							image: this.imageLoader(
								{ assetId: subCategory.link.assetId },
								'megamenu/brand',
								'v3-brand',
								{},
								{ width: 100 },
							),
						})),
					highlightedBrands: {
						uid: 'This is List',
						more: { href: 'url', text: this.$t(this.$i18nKeys.list.moreCategories) },
						links: data.category.trendyCategories
							.filter((trendyCategory) => !trendyCategory.hide && !trendyCategory.hideInNavigation)
							.slice(0, FLYOUT_HIGHLIGHTED_BRANDS_AMOUNT)
							.map((trendyCategory) => ({
								text: trendyCategory.link.title,
								href: trendyCategory.link.url,
							})),
					},
					brands: data.category.subCategories
						.filter((subCategory) => !subCategory.hide && !subCategory.hideInNavigation)
						.map(
							(subCategory): EnhancedBrand => ({
								uid: subCategory.id,
								name: subCategory.link.title,
								url: subCategory.link.url,
							}),
						),
				},
			};
		},
		setFlyoutImage(data: MegaDropdown): HeaderFlyout {
			return {
				flyoutType: FlyoutType.IMAGE,
				flyoutData: {
					uid: data.category.id,
					parentCategory: { text: data.category.link.title, href: data.category.link.url },
					cards: data.category.subCategories
						.filter((subCategory) => !subCategory.hide && !subCategory.hideInNavigation)
						.map(
							(subCategory): Card => ({
								image: {
									media: this.imageLoader(
										{ assetId: subCategory.link.assetId },
										'int',
										'v3-teaser',
										{ '1280': 247 },
										{ width: 170 },
									),
									aspectRatio: { width: 4, height: 3 },
								},
								list: {
									uid: subCategory.id,
									heading: { href: subCategory.link.url, text: subCategory.link.title },
									links: subCategory.subCategories
										.filter(
											(subCategoryChild) =>
												!subCategoryChild.hide && !subCategoryChild.hideInNavigation,
										)
										.map((subCategoryChild) => ({
											text: subCategoryChild.link.title,
											href: subCategoryChild.link.url,
										})),
									more: { text: this.$t(this.$i18nKeys.list.morePages), href: `#URL_MORE_1` },
								},
							}),
						),
				},
			};
		},
		imageLoader(
			image: Image,
			path: string,
			preset: string,
			breakpoints: {
				[breakpoint: string]: number;
			},
			options?: object,
		): MediaModel['media'] {
			const _options: ImageOptions = {
				preset,
				path,
				seoText: image.altText || image.title,
				...options,
			};
			const sources = createImageSources(this.$store, image, breakpoints, _options);
			const src = calculateImageUrl(this.$store, image, { ..._options });

			return { sources, src, alt: image.altText };
		},
		searchRedirect(query: string) {
			useRoutingStore(this.$store).api.navigate(backend.URL.PRODUCTS_SEARCH(this.$store, query));
		},
		setItems(parentItem, currentItemPath: string[], level = 1) {
			return (
				parentItem
					?.filter((child) => !child.hide && !child.hideInNavigation)
					.map((child) => {
						const obj: LinkWithChildren = {
							uid: child.id,
							text: child.link.title,
							href: child.link.url,
						};

						if (child.subCategories.length) {
							obj.items = this.setItems(child.subCategories, currentItemPath, level + 1);
						}

						return obj;
					})
					.sort((curr, next) => {
						const moveToTop = level === 1 && currentItemPath.includes(curr.uid);

						return moveToTop ? -1 : curr.text.localeCompare(next.text);
					}) ?? []
			);
		},
		setBrandsItems(brandsUrl, brands) {
			const filters: Record<string, Link[]> = DEFAULT_FILTERS.reduce((acc, curr) => {
				acc[curr] = [];

				return acc;
			}, {});

			brands
				.filter((brand) => !brand.hide && !brand.hideInNavigation)
				.forEach((brand) => {
					const letter = brand.link.title[0].toUpperCase();
					let key = letter;

					if (!filters[letter]) {
						key = FILTER_SPECIAL;
					}

					filters[key].push({ uid: brand.id, text: brand.link.title, href: brand.link.url });
				});

			return Object.entries(filters).map(([key, links]) => {
				return {
					uid: `BRANDS_${key}`,
					text: key,
					href: brandsUrl,
					items: sortBy(links, 'text', true),
					searchItems: links,
					searchPlaceholder: this.$t(this.$i18nKeys.header.mobile.brands.search.placeholder),
				};
			});
		},
		getLogoutTriggerData(): TriggerModel {
			return {
				uid: 'HEADER_MODAL_TRIGGER_LOGOUT',
				tag: TriggerTag.A,
				href: url(this.$store, '/logout'),
				theme: TriggerTheme.OUTLINED,
				icon: 'arrow-up-right-small',
			};
		},
		getNavigationListData(): NavigationListModel {
			return {
				uid: 'HEADER_MODAL_NAVIGATION_LIST',
				navigationItems: (useMyAccountStore(this.$store).state.navigationItems || []).map(
					(navigationItem) => ({
						url: url(this.$store, navigationItem.url as string),
						title: navigationItem.title as string,
					}),
				),
			};
		},
		setModalVisibility(data: boolean) {
			this.isModalVisible = data;
		},
		setUserAccount() {
			const { customerNumber } = useUserStore(this.$store).state.user;

			this.userAccount = this.$t(this.$i18nKeys.header.modal.account, { customerNumber });
		},
		handleLoginSubmit(data: LoginData) {
			importRunTask().then(({ runTask }) =>
				runTask(async () => {
					const { api: userApi, state: userState } = useUserStore(this.$store);
					const success = await userApi.login(data);

					if (success) {
						this.setModalVisibility(false);

						this.messageboxData = null;
					} else {
						const remaining = userState.response.details.remainingTries;

						if (remaining > 3) return;

						let type = MessageboxType.INFO;
						let text = this.$t(this.$i18nKeys.header.modal.login.warning, { remaining });

						if (remaining <= 0) {
							type = MessageboxType.ERROR;
							text = this.$t(this.$i18nKeys.header.modal.login.error);
						}

						this.messageboxData = { text, type, dismissible: false };
					}
				}),
			);
		},
		handleRegisterSubmit(data: RegistrationRequest) {
			importRunTask().then(({ runTask }) =>
				runTask(async () => {
					const success = await useUserStore(this.$store).api.registration(
						data,
						false,
						this.$t(this.$i18nKeys.header.modal.register.success),
					);

					if (success) {
						this.setModalVisibility(false);
					}
				}),
			);
		},
	},
});
