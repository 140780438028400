import { PromoBarModel } from '@/node_modules/@osp/design-system/components/PromoBar/PromoBar.props';
import { FONTSIZE } from '@/node_modules/@osp/design-system/types/text';
import DyCustomComponentBase from '../dy-custom-component-base';
import { DYPromoBarProps } from './dy-promo-bar.props';
import { DYNAMICYIELD_A_REPORT } from '~/@constants/store';

// Component ---------------------------------------------------------------------------------------

export default DyCustomComponentBase.extend({
	name: 'DyPromoBar',
	components: {
		PromoBarComponent: () =>
			import('~/node_modules/@osp/design-system/components/PromoBar/PromoBar'),
	},
	props: DYPromoBarProps,
	async fetch() {
		await this.fetchDy(this.selectorName, this.updateStore, true);
	},
	watch: {
		dataKey() {
			this.$fetch();
		},
	},
	computed: {
		promoBarData(): PromoBarModel {
			// Component data needs to be received out of store
			return this.dyStoredData;
		},
	},
	methods: {
		clickHandler() {
			if (!this.dyChoice) return;

			this.$store.dispatch(DYNAMICYIELD_A_REPORT, [
				{
					type: 'CLICK',
					decisionId: this.dyChoice.decisionId,
				},
			]);
		},

		updateStore(): void {
			const dyData = this.dyVariation?.payload?.data;

			// If no dyData -> keep store entry but set to undefined
			// (keep the entry but with data undefined prevents also from re-fetching after SSR)
			if (!dyData) {
				this.storeData(undefined);

				return;
			}

			// Ensure month and day values have always 2 characters
			const padZero = (num) => String(num).padStart(2, '0');

			// Countdown target as ISO string for promobar property
			const countdownTargetString = ''.concat(
				dyData.countdownTarget.year,
				'-',
				padZero(dyData.countdownTarget.month),
				'-',
				padZero(dyData.countdownTarget.day),
				'T',
				dyData.countdownTarget.time,
				':00+0200',
			);

			// Workaround: DY colorpicker can not be set to have no value, so white 100% transparent means 'no coloring'
			const noColoringValue = 'rgba(255, 255, 255, 0)';

			const componentData: PromoBarModel = {
				uid: [this.uid, this.dyVariation?.id].join('-'),
				href: dyData.href ? dyData.href[this.dyLanguage] : undefined,
				text: {
					content: {
						text: dyData.text ? dyData.text[this.dyLanguage] : '',
						size: dyData.textSize ? this.mapTextSize(dyData.textSize[this.dyLanguage]) : undefined,
						customColor:
							dyData.colors.barText !== noColoringValue ? dyData.colors.barText : undefined,
					},
					customBackgroundColor:
						dyData.colors.barBackground !== noColoringValue
							? dyData.colors.barBackground
							: undefined,
				},
				label: {
					content: {
						text: dyData.label ? dyData.label[this.dyLanguage] : '',
						customColor:
							dyData.colors.labelText !== noColoringValue ? dyData.colors.labelText : undefined,
					},
					customBackgroundColor:
						dyData.colors.labelBackground !== noColoringValue
							? dyData.colors.labelBackground
							: undefined,
				},
				cta: {
					content: {
						text: dyData.ctaText ? dyData.ctaText[this.dyLanguage] : undefined,
						customColor:
							dyData.colors.ctaText !== noColoringValue ? dyData.colors.ctaText : undefined,
					},
					customBackgroundColor:
						dyData.colors.ctaBackground !== noColoringValue
							? dyData.colors.ctaBackground
							: undefined,
				},
				countdownTarget: isNaN(Date.parse(countdownTargetString))
					? undefined
					: countdownTargetString,
			};

			// Parsed data needs to be putted into the store
			this.storeData(componentData);
		},
		mapTextSize(mappingValue: string): string | undefined {
			if (typeof mappingValue === 'undefined') {
				return undefined;
			}

			const sizeMappings = [
				{ key: 'small', value: this.BREAKPOINT.IS_MOBILE ? FONTSIZE.TEXT_SM : FONTSIZE.TEXT_BASE },
				{ key: 'medium', value: this.BREAKPOINT.IS_MOBILE ? FONTSIZE.TEXT_BASE : FONTSIZE.TEXT_XL },
				{ key: 'large', value: this.BREAKPOINT.IS_MOBILE ? FONTSIZE.TEXT_LG : FONTSIZE.TEXT_3XL },
			];

			return sizeMappings.find((item) => item.key === mappingValue.toLowerCase())?.value;
		},
	},
	beforeDestroy() {
		this.clearDyDataWhenInBrowser();
	},
});
