import { render, staticRenderFns } from "./header.vue?vue&type=template&id=606fc440&"
import script from "./header.ts?vue&type=script&lang=ts&"
export * from "./header.ts?vue&type=script&lang=ts&"
import style0 from "./header.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UspBar: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/UspBar/UspBar.vue').default,ThreadTaskWrapper: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/ThreadTaskWrapper/ThreadTaskWrapper.vue').default,DyPromoBar: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/components/dynamic-yield/dy-promo-bar/dy-promo-bar.vue').default,HtmlFragment: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/HtmlFragment/HtmlFragment.vue').default,LoginForm: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/components/molecules/forms/login-form/login-form.vue').default,TabsItem: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Tabs/partials/Tabs__Item.vue').default,RegistrationForm: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/components/molecules/forms/registration-form/registration-form.vue').default})
