import type { Store } from 'vuex';
import { useStoreModule } from '../useStoreModule';
import myAccountStore from './myAccount.store';
import myAccountApi from './myAccount.api';
import { MyAccountState, RootState } from '~/@api/store.types';
import { MYACCOUNT } from '~/@constants/store';

export function useMyAccountStore(store: Store<RootState>) {
	return useStoreModule<MyAccountState, ReturnType<typeof myAccountApi>>(
		MYACCOUNT,
		store,
		myAccountStore,
		myAccountApi,
	);
}
