var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"tw-group tw-relative tw-flex tw-mb-1 tw-select-none tw-items-center",class:{
		'tw-text-dust tw-cursor-not-allowed': _vm.disabled,
		'tw-cursor-pointer': !_vm.disabled,
		'tw-text-shiraz': _vm.hasError,
		'tw-inline-block': !_vm.label && !_vm.$slots.default,
		'tw-text-3.5 tw-font-bold': !_vm.clearLabelStyles,
	},attrs:{"data-name":"checkbox","data-id":_vm.uid}},[_c('input',{staticClass:"tw-sr-only tw-opacity-0",attrs:{"id":_vm.uid,"type":"checkbox","disabled":_vm.disabled,"aria-label":_vm.fallbackAriaLabel},domProps:{"value":_vm.value,"checked":_vm.isChecked},on:{"change":_vm.updateInput}}),_c('div',{staticClass:"tw-w-6 tw-h-6 tw-flex tw-flex-shrink-0 tw-content-center tw-justify-center tw-border tw-border-solid",class:{
			'tw-mr-4': (_vm.label || _vm.$slots.default) && !_vm.hideLabel,
			'tw-border-shiraz tw-bg-white': _vm.hasError,
			'tw-border-shiraz tw-text-shiraz': _vm.hasError && _vm.isChecked,
			'tw-border-dust tw-bg-white': !_vm.hasError && !_vm.disabled && !_vm.isChecked,
			'tw-border-black tw-bg-black tw-text-white': !_vm.hasError && !_vm.disabled && _vm.isChecked,
			'tw-border-dust tw-bg-snow tw-text-dust': _vm.disabled,
			'group-hover:tw-bg-dove': !_vm.disabled && !_vm.isChecked,
		},style:(_vm.customStyles())},[(_vm.isChecked)?_c('Ico',{staticClass:"tw--mt-px tw--mr-px",attrs:{"name":"done","size":"24px"}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideLabel),expression:"!hideLabel"}],ref:"labelWrap",staticClass:"tw-mt-0.5"},[_vm._t("default",function(){return [(_vm.label)?_c('HtmlFragment',{attrs:{"wrapping-tag":"div","html":_vm.label}}):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }