var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._b({tag:"Component",staticClass:"trigger tw-inline-flex tw-items-center tw-justify-center tw-no-underline",class:{
		'tw-text-center': !_vm.isTheme.rounded,
		'tw-text-left': _vm.isTheme.rounded,
		'tw-border tw-px-3 tw-py-2.5': _vm.isTheme.narrow,
		'tw-border tw-px-5 tw-py-4': _vm.isTheme.cta || _vm.isTheme.outlined,
		'tw-px-5 tw-py-3 tw-text-3.5 tw-font-secondary tw-tracking-wide': _vm.isTheme.bright,
		'tw-border tw-px-4 tw-py-4 tw-border-snow tw-rounded tw-text-storm': _vm.isTheme.rounded,
		'trigger--fake-disabled': _vm.fakeDisabled,
		'tw-border-black': (!_vm.disabled && !_vm.fakeDisabled && _vm.isTheme.outlined) || _vm.isTheme.narrow,
		'tw-bg-black': _vm.isTheme.cta,
		'tw-text-white': _vm.isTheme.cta && !_vm.disabled && !_vm.fakeDisabled && !_vm.loading,
		'tw-cursor-not-allowed tw-outline-0': _vm.disabled || _vm.fakeDisabled || _vm.loading,
		'tw-bg-snow tw-border-snow': (_vm.disabled || _vm.fakeDisabled) && !_vm.isTheme.text,
		'tw-text-storm': (_vm.loading || _vm.disabled || _vm.fakeDisabled) && !_vm.isTheme.text,
		'tw-text-dust': (_vm.loading || _vm.disabled || _vm.fakeDisabled) && _vm.isTheme.text,
		'tw-flex-row-reverse': _vm.triggerIconPosition.left,
		'tw-relative': _vm.loading && !_vm.icon,
		'tw-text-3.5 hover:tw-bg-dove': _vm.isTheme.narrow,
	},attrs:{"data-name":"trigger","data-id":_vm.uid,"disabled":_vm.disabled}},'Component',_vm.props,false),[_c('span',{staticClass:"tw-block",class:{
			'tw-pb-0.5 tw-border-b hover:tw-border-black': _vm.isTheme.text,
			'tw-border-dust hover:tw-border-dust':
				(_vm.disabled || _vm.fakeDisabled || _vm.loading) && _vm.isTheme.text,
			'tw-w-full': _vm.isTheme.rounded,
		}},[_vm._t("default")],2),(_vm.icon || _vm.loading)?_c('Ico',{staticClass:"tw-ml-2",class:{
			'tw-opacity-50': !_vm.loading && !_vm.isTheme.rounded && !_vm.isTheme.narrow,
			'tw-animate-spin': _vm.loading,
			'hover:tw-opacity-100': !_vm.disabled && !_vm.fakeDisabled && !_vm.isTheme.narrow,
			'tw-absolute tw--right-5': _vm.loading && !_vm.icon && _vm.isTheme.text,
			'tw-absolute tw-right-1': _vm.loading && !_vm.icon && (_vm.isTheme.outlined || _vm.isTheme.narrow),
			'tw-text-black': _vm.loading & !_vm.isTheme.cta,
			'tw-text-white': _vm.loading && _vm.isTheme.cta,
			'tw-ml-2': _vm.hasText,
		},attrs:{"name":_vm.triggerIcon,"size":_vm.isTheme.rounded ? '24px' : '16px'}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }