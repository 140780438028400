import { ActionContext } from 'vuex';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { MyAccountState, RootState } from '~/@api/store.types';
import {
	mapFn,
	MYACCOUNT_A_FETCH_NAVIGATION,
	MYACCOUNT_A_FETCH_ORDERS,
	MYACCOUNT_A_UPDATE_PERSONAL_DETAILS,
	MYACCOUNT_M_SAVE_ORDER,
	MYACCOUNT_M_STORE_NAVIGATION,
	MYACCOUNT_M_STORE_ORDERS,
} from '~/@constants/store';
import { getJson, postJson } from '~/app-utils/http';
import { backend } from '~/@api/backend';
import { ContentNavigationNode, Order, PersonalDetailsRequest } from '~/generated/hybris-raml-api';

// ---------------------------------------------------------------------------
// initial state
// ---------------------------------------------------------------------------
export const state = (): MyAccountState => ({
	navigationItems: null,
	orders: null,
});

// ---------------------------------------------------------------------------
// Getter
// ---------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Mutations
// --------------------------------------------------------------------------
export const mutations = {
	[mapFn(MYACCOUNT_M_SAVE_ORDER)](_state: MyAccountState, order: Order) {
		const orderIndex = _state.orders.findIndex(({ code }) => code === order.code);
		const orders = [..._state.orders];

		if (orderIndex >= 0) {
			orders[orderIndex] = order;
		} else {
			orders.push(order);
		}

		_state.orders = orders;
	},
	[mapFn(MYACCOUNT_M_STORE_NAVIGATION)](
		_state: MyAccountState,
		navigationItems: ContentNavigationNode[],
	) {
		_state.navigationItems = navigationItems;
	},
	[mapFn(MYACCOUNT_M_STORE_ORDERS)](_state: MyAccountState, orders: Order[]) {
		_state.orders = orders;
	},
};

// --------------------------------------------------------------------------
// Actions
// --------------------------------------------------------------------------
export const actions = {
	async [mapFn(MYACCOUNT_A_FETCH_NAVIGATION)](context: ActionContext<MyAccountState, RootState>) {
		if (context.state.navigationItems?.length > 0) {
			return context.state.navigationItems;
		}

		try {
			const response = await getJson(backend.API.V2.MY_ACCOUNT.NAVIGATION(this), this);
			const navigation = response?.json?.links;
			context.commit(mapFn(MYACCOUNT_M_STORE_NAVIGATION), navigation);
			return navigation;
		} catch (error) {
			Logger.error(`Error while fetching myAccount navigation: ${error}`);
		}
	},

	async [mapFn(MYACCOUNT_A_FETCH_ORDERS)](
		context: ActionContext<MyAccountState, RootState>,
		amount?: number,
	) {
		try {
			const response = await getJson(backend.API.V2.MY_ACCOUNT.ORDER.HISTORY(this, amount), this);
			const orders = response.json.orders;
			context.commit(mapFn(MYACCOUNT_M_STORE_ORDERS), orders);
			return orders;
		} catch (error) {
			Logger.error(`Error while fetching myAccount navigation: ${error}`);
		}
	},

	async [mapFn(MYACCOUNT_A_UPDATE_PERSONAL_DETAILS)](
		_context: ActionContext<MyAccountState, RootState>,
		data: PersonalDetailsRequest,
	) {
		try {
			const response = await postJson(
				backend.API.V2.MY_ACCOUNT.PERSONAL_DETAILS(this),
				data,
				this,
				null,
				true,
			);
			return response;
		} catch (error) {
			Logger.error(`Error while updating myAccount personal details: ${error}`);
		}
	},
};

export default {
	state,
	mutations,
	actions,
};
