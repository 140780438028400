import { Component, Vue } from '@/app-utils/decorators';
import Messagebox from '@/components/molecules/messagebox/messagebox.vue';
import { useMessageboxStore } from '~/@api/store/messageboxApi';
import { Messagebox as MessageboxType } from '~/@api/store.types';

@Component
export default class MessageboxWrapper extends Vue {
	get globalMessages() {
		return useMessageboxStore(this.$store).state.messageboxes.filter(
			(messagebox) => !messagebox.key || messagebox.global,
		);
	}

	mounted() {
		useMessageboxStore(this.$store)
			.state.messageboxes.filter((messagebox) => messagebox.key && !messagebox.global)
			.forEach((messagebox) => {
				if (document.querySelector(`#messagebox__${messagebox.key}`)) {
					// eslint-disable-next-line no-new
					new Vue({
						el: `#messagebox__${messagebox.key}`,
						render: (h) =>
							h(Messagebox, { attrs: { data: messagebox }, on: { dismiss: this.dismiss } }),
						store: this.$store,
					});
				}
			});
	}

	dismiss(messagebox: MessageboxType) {
		useMessageboxStore(this.$store).api.dismiss(messagebox);
	}
}
