import { track } from '../tracking';

export const suggestedSearchClicked = (
	term: string,
	gender: string,
	resultType: string,
	resultPage: string,
	clickedText: string,
) => {
	track('suggestedSearch', {
		actions: {
			search: { gender, resultPage, resultType, term, clickedText },
		},
	});
};
